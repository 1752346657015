import React from "react";

import { Box, Button } from "@material-ui/core";
import clsx from "clsx";

import {
  Icon,
  IconName,
  getTotalPriceText,
  hotelPriceFormatter,
} from "halifax";
import {
  ListingSearchResult,
  Lodging,
  LodgingCollectionEnum,
  LodgingId,
  VRAvailabilityResultEnum,
} from "redmond";

import { ISetPropertyIdInFocus } from "../../../../actions/actions";
import { AvailabilityMapHotelTooltip } from "../AvailabilityMapHotelTooltip";
import { ConnectedAvailabilityMapPricePinProps } from "./container";

import "./styles.scss";

export interface IAvailabilityMapPricePinProps
  extends ConnectedAvailabilityMapPricePinProps {
  property: Lodging | ListingSearchResult;
  isInFocus: boolean;
  isHovered: boolean;
  isPreviouslyShown: boolean;
  isPreview?: boolean;
  isDesktop?: boolean;
  setPropertyIdInFocus: (lodgingId: LodgingId | null) => ISetPropertyIdInFocus;
  goToPropertyDetails: (propertyId: LodgingId | string) => void;
}

export const AvailabilityMapPricePin = (
  props: IAvailabilityMapPricePinProps
) => {
  const {
    property,
    isInFocus,
    isHovered,
    isPreviouslyShown,
    setPropertyIdInFocus,
    isPreview,
    isDesktop,
    goToPropertyDetails,
  } = props;

  const [hovered, setHovered] = React.useState(isHovered);
  React.useEffect(() => {
    setHovered(isHovered);
  }, [isHovered]);

  const propertyId =
    "lodging" in property ? property.lodging.id : property.listingId.id;
  const onClickPricePin = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPropertyIdInFocus(isInFocus ? null : propertyId);
  };

  const isUnavailableVR = React.useMemo(() => {
    return "availability" in property
      ? property.availability.AvailabilityResult ===
          VRAvailabilityResultEnum.Unavailable
      : false;
  }, [property]);

  const propertyPrice = React.useMemo(() => {
    return "availability" in property
      ? property.availability.rate?.price
      : property.price;
  }, [property]);

  const collection =
    "lodging" in property
      ? property.lodgingCollection
      : property.listing.listingCollection;
  const showPCStyling = collection === LodgingCollectionEnum.Premier;
  const showLCStyling = collection === LodgingCollectionEnum.Lifestyle;

  return (
    <>
      {(propertyPrice || isUnavailableVR) && (
        <Box
          className={clsx("lodging-marker-wrapper", {
            inFocus: isInFocus,
            hovered: isInFocus || hovered,
            previouslyShown: isPreviouslyShown,
            "premier-collection": showPCStyling,
            "lifestyle-collection": showLCStyling,
          })}
        >
          {isInFocus && isDesktop && !isUnavailableVR && (
            <AvailabilityMapHotelTooltip
              onClickHotelDetails={(e) => {
                e.stopPropagation();
                setPropertyIdInFocus(propertyId);
                goToPropertyDetails(propertyId);
              }}
              onCloseClick={(e) => {
                e.stopPropagation();
                setPropertyIdInFocus(null);
              }}
              lodging={property}
            />
          )}
          <Button
            className="pc-price-pin-wrapper price-pin"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={(e) => (!isPreview ? onClickPricePin(e) : null)}
          >
            {isUnavailableVR ? (
              <Icon name={IconName.UnavailableMapPin} />
            ) : (
              <>
                <Icon name={IconName.PremierCollectionPricePin} />
                <Box className="price-pin-label">
                  {"lodging" in property
                    ? getTotalPriceText({
                        price:
                          property.price?.nightlyDiscountAware
                            ?.priceWithUnmanagedDiscounts.fiat ||
                          property.price!.nightlyPrice.fiat,
                        priceFormatter: hotelPriceFormatter,
                      })
                    : getTotalPriceText({
                        price: property.availability.rate!?.price.nightly.fiat,
                        priceFormatter: hotelPriceFormatter,
                      })}
                </Box>
              </>
            )}
          </Button>
        </Box>
      )}
    </>
  );
};
